@charset "UTF-8";

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// 2. Vendors
@import
  'vendors/normalize';

// 3. Base stuff
@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/image',
  'base/video',
  'base/colorways',
  'base/helpers';

// 4. Layout-related sections
@import
  'layout/header',
  'layout/footer',
  'layout/main';

// 5. Components
@import
  'components/ticker',
  'components/pointerlines',
  'components/services',
  'components/projects',
  'components/clients',
  'components/quote',
  'components/contentblock',
  'components/logos',
  'components/button',
  'components/nav',
  'components/cursor',
  'components/cookie',
  'components/styleguide';
// 6. Page-specific styles
@import
  'pages/home',
  'pages/specialcase';

// 7. Themes
@import
  'themes/default';